<template>
  <v-container fluid>
    <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
      <form @submit.prevent="handleSubmit(updateSettings())">
        <v-row dense>
          <v-col cols="12" lg="6" md="12">
            <v-card>
              <v-card-text>
                <kurcc-locale-selector/>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="logout()">{{ $vuetify.lang.t('$vuetify.pages.settings.signOut') }}</v-btn>
                <v-spacer/>
                <v-btn text @click="reset()">{{ $vuetify.lang.t('$vuetify.pages.settings.reset') }}</v-btn>
                <v-btn :disabled="invalid" :loading="submittingData" text type="submit">
                  {{ $vuetify.lang.t('$vuetify.pages.settings.update') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
    <v-overlay v-if="loggingOut">
      <v-progress-circular indeterminate/>
    </v-overlay>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'KurccSettingsPage',
  components: {
    KurccLocaleSelector: () => import('@/modules/app/components/KurccLocaleSelector'),
    ValidationObserver
  },
  data () {
    return {
      submittingData: false,
      loggingOut: false
    }
  },
  methods: {
    reset () {
      this.validateForm()
    },
    updateSettings () {
      this.submittingData = true

      // TODO Implement 'update settings' functionality
      console.log('update settings')
    },
    validateForm () {
      if (this.$refs.observer) {
        this.$refs.observer.validate()
      }
    },
    logout () {
      this.loggingOut = true
      this.$store.dispatch('logout').finally(() => {
        this.loggingOut = false
      })
    }
  },
  created () {
    this.reset()
  }
}
</script>
